import {Italian}  from "flatpickr/dist/l10n/it.js";
import {German}  from "flatpickr/dist/l10n/de.js";
import {French}  from "flatpickr/dist/l10n/fr.js";

// Found in C:\amp\www\desy-test\node_modules\flatpickr\dist\l10n

//console.log(it);

export default {
    it: Italian,
    de: German,
    fr: French
}