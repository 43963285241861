/*
 * This file is part of the CloserPHP package.
 * For the full copyright and license information, please view the LICENSE.php
 * file that was distributed with this source code.
 * Info:andread.dev@gmail.com
 */

import $ from "jquery";

export default function task(task, param) {
    task = typeof task !== 'undefined' ? task : "";
    param = typeof param !== 'undefined' ? param : "";

    task = task
            .replace("\\", "/")
            .replace(/([A-Z])/g, "-$1")
            .toLowerCase()
            .replace(/\-+/g, '-')
            .replace(/\-\/|\/\-/g, '/')
            .replace(/^-+|-+$/g, '');

    var query = "";

    if (task != "") {
        query += "t=" + task;
    }

    if (param != "") {
        query += ((query !== "") ? "&" : "") + param;
    }

    if (cpAppSet.currentLang !== cpAppSet.defaultLang) {
        query += ((query !== "") ? "&" : "") + "l=" + cpAppSet.currentLang;
    }
    return cpAppSet.baseUrl + ((query !== "") ? ("?" + query) : "");
}

export function thisTask(method, param) {
    method = typeof method !== 'undefined' ? method : "";
    param = typeof param !== 'undefined' ? param : "";

    var taskParam = cpAppSet.task.current + (method ? ("." + method) : "");
    return task(taskParam, param);
}