import it from "./it"
import de from "./de"
import fr from "./fr"

// Found in node_modules\jquery-validation\dist\localization

export default {
    it: it,
    de: de,
    fr: fr
}