/*
 * This file is part of the CloserPHP package.
 * For the full copyright and license information, please view the LICENSE.php
 * file that was distributed with this source code.
 * Info:andread.dev@gmail.com
 */

import $ from "jquery";

export default function useFormUtils(form, options = {}) {
    options = $.extend({}, {messageWraper: ".form-message-wraper"}, options);
    form = $(form);

    /*Info: Can i use - but it add another dangerous separator (in name field) along with php dot*/

    function flatObject(input) {
        function flat(res, key, val, pre = '') {
            const prefix = [pre, key].filter(v => v).join('.');
            return typeof val === 'object'
                    ? Object.keys(val).reduce((prev, curr) => flat(prev, curr, val[curr], prefix), res)
                    : Object.assign(res, {[prefix]: val});
        }

        return Object.keys(input).reduce((prev, curr) => flat(prev, curr, input[curr]), {});
    }

    function escapeSelector(selector) {
        return selector.replace(/([\\!"#$%&'()*+,./:;<=>?@\[\]^`{|}~])/g, "\\$1");
    }

    /* return nameId*/
    function fieldSelector(fieldName) {
        var nameId = fieldName.
                replace("][", ".")
                .replace("[", ".")
                .replace("]", ".")
                .replace(/^\.+|\.+$/g, ''); // Trim dash
        return escapeSelector(nameId);
    }

    function fieldComponents(nameId) {
        return {
            label: ".cp-lbl-" + nameId,
            field: ".cp-fld-" + nameId,
            feedback: ".cp-msg-" + nameId,
        }
    }

    function showFieldError(nameId, message) {
        const  {label, field, feedback} = fieldComponents(nameId);

        if ($(label, form).length) {
            $(label, form).addClass("invalid-lbl-feedback");
        }

        if ($(field, form).length) {
            $(field, form).addClass("is-invalid").attr('aria-invalid', 'true');
        }

        if ($(feedback, form).length) {
            $(feedback, form).html(message).removeClass('d-none').addClass("d-block");
        } else {
            console.log("Error field " + nameId + " not found");
        }
    }

    function hideFieldError(nameId) {
        const  {label, field, feedback} = fieldComponents(nameId);

        if ($(label, form).length) {
            $(label, form).removeClass("invalid-lbl-feedback");
        }

        if ($(field, form).length) {
            $(field, form).removeClass("is-invalid");
            if ($(field, form).attr("aria-invalid")) {
                $(field, form).attr('aria-invalid', 'false');
            }
        }

        if ($(feedback, form).length) {
            $(feedback, form).html("").removeClass('d-block').addClass("d-none");
        } else {
            console.log("Error field " + nameId + " not found");
        }
    }

    return $.extend(form, {
        field: function (name) {
            var nameId = fieldSelector(name);

            return $.extend($('[name=' + escapeSelector(name) + ']', form), {
                showError: function (message) {
                    showFieldError(nameId, message)
                },
                hideError: function () {
                    hideFieldError(nameId)
                },
            }
            );
        },
        hasField: function (name) {
            return $('[name=' + escapeSelector(name) + ']', form).length > 0;
        },
        setField: function (name, value) {
            $('[name=' + escapeSelector(name) + ']', form).val(value);
        },
        fieldSelector: function (name) {
            return fieldSelector(name);
        },
        fieldComponents: function (name) {
            return fieldComponents(fieldSelector(name));
        },
        showErrors(errorFields) {
            errorFields = flatObject(errorFields);
            $.each(errorFields, function (nameId, value) {
                showFieldError(escapeSelector(nameId), value)
            });
        },
        clearErrors() {
            $(".invalid-feedback", form).removeClass('d-block').addClass("d-none");
            $(".invalid-lbl-feedback", form).removeClass("invalid-lbl-feedback");
            $(".is-invalid", form).removeClass("is-invalid");
        },
        showMessage(message, type = "warning") {
            var content = `<div class="alert alert-${type} alert-dismissible fade show mb-0" role="alert">
            ${message}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close">
            </button>
          </div>`;

            $(options.messageWraper, form).empty().html(content);

        },
        hideMessage() {
            $(options.messageWraper, form).empty();
        },
        clearErrorOnFocus() {
            $("input,select,textarea", form).not(":submit").focus(function () {
                if (!$(this).attr("name")) {
                    return;
                }
                hideFieldError(fieldSelector($(this).attr('name')));
            });
        },
        reset() {
            $(form)[0].reset();
            $("reset-clear", form).val("");
        }
    });
}


