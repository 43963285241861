import $ from "jquery";
import autoComplete from "@tarekraafat/autocomplete.js";

import {__t} from "@commons/closerphp/i18n";
import task, {thisTask} from "@commons/closerphp/task";
import useFormUtils from "@commons/closerphp/useFormUtils";

import flatpickr from "flatpickr";
import flatpickrLocale from "./locale/flatpickr/index";
if (flatpickrLocale.hasOwnProperty(cpAppSet.currentLang)) {
    flatpickr.localize(flatpickrLocale[cpAppSet.currentLang]);
}

import 'jquery-validation';
import validationLocale from "./locale/validation/index";
if (validationLocale.hasOwnProperty(cpAppSet.currentLang)) {
    $.extend($.validator.messages, validationLocale[cpAppSet.currentLang]);
    //console.log(validationLocale[cpAppSet.currentLang]);
}

//Fix email validation
$.validator.addMethod("emailfull", function (value, element) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return value && regex.test(value);
}, $.validator.messages.email);

/*  MULTISTEP FORM */
var form = $("#signUpForm");
var formUtils = useFormUtils(form);
var loader = $(".loader-overlay", form);

// Multistep
var stepValidationConfig = [
    // Step 1
    {
        rules: {
            email: "emailfull"
        },

    },
    // Step 2
    {
        rules: {
            // Use html default
        },
    }
];

var currentTab = 0; // Current tab is set to be the first tab (0)
function showTab(n) {

    var steps = $(".step");
    var stepsIndicator = $(".stepIndicator");

    if (n > steps.length || n < 0) {
        console.log("Invalid step number", n);
        return;
    }

    $(steps).hide().removeAttr("aria-current");
    $(steps[n]).show().attr("aria-current", "step");

    //fixStepIndicator
    $(stepsIndicator).removeClass("active");
    $(stepsIndicator[n]).addClass("active");

    currentTab = n;
}

showTab(currentTab); // Display the current tab

function nextPrev(n) {

    if (n === currentTab) {
        return;
    } else if (n < currentTab) {
        // back
    } else {// Next validate current visibile step

        var validationConfig = $.extend({}, {
            errorElement: "em",
            ignore: ":hidden",
            errorPlacement: function (error, element) {
                var filed = formUtils.field($(element).attr('name'));
                if (filed) {
                    filed.showError(error);
                } else {
                    console.error("Missing errorPlacement field: " + $(element).attr('name'));
                }
            },
            highlight: function (element, errorClass, validClass) {
                //console.log(element);                
                //$(element).addClass("is-invalid").attr("aria-invalid", "true");
            },
            unhighlight: function (element, errorClass, validClass) {
                var filed = formUtils.field($(element).attr('name'));
                if (filed) {
                    filed.hideError();
                } else {
                    console.error("Missing unhighlight field: " + $(element).attr('name'));
                }
            }

        }, stepValidationConfig[currentTab]);

        $(form).validate(validationConfig);
        if (!form.valid()) {
            return;
        }

        if (n === $(".step").length) {
            grecaptcha.ready(function () {
                grecaptcha.execute($('#googlerecaptcha').data("key"), {action: 'submit'}).then(function (token) {
                    $('#googlerecaptcha').val(token);
                    sendForm();
                });
            });
            return;
        }

    }

    showTab(n);
}

// previous btn
$(".prevBtn").on("click", function (e) {
    e.preventDefault();
    nextPrev(currentTab - 1);
});

// next btn
$(".nextBtn").on("click", function (e) {
    e.preventDefault();
    nextPrev(currentTab + 1);
});

// Setup datepiker
var checkinDateEl = $('#checkin_date');
var checkoutDateEl = $('#checkout_date');

var fpDateFormat = {
    it: "d/m/Y",
    de: "d.m.Y",
    fr: "d/m/Y",
    en: "m/d/Y"
};

flatpickr(checkinDateEl, {
    minDate: "today",
    dateFormat: fpDateFormat[cpAppSet.currentLang],
    disableMobile: "true"}
);

$(checkinDateEl).on("change", function (e) {
    $(checkoutDateEl).prop('disabled', false);

    var minDate = flatpickr.parseDate(
            e.target.value,
            fpDateFormat[cpAppSet.currentLang]
            );

    flatpickr(checkoutDateEl, {
        minDate: minDate,
        dateFormat: fpDateFormat[cpAppSet.currentLang],
        disableMobile: "true"
    }).clear();
});

// Setup infopoint selector
$("#has-infopoint", form).on("change", function (e) {
    e.preventDefault();
    var isChecked = $(this).is(':checked');

    $("#infopoint-area")
            .toggle(isChecked)
            .find("#infopoint_id")
            .attr("required", "true")
            .attr("aria-required", isChecked ? "true" : "false");
});

// Setup cities search
if (typeof _cities_autocomplete_list !== 'undefined') {

// Setup autoCompleteJS vacation city
    var validDestination = ""; // Vale anche per completamento da remoto
    var destinationAutoComplete = new autoComplete({
        selector: "#vacation_city",
        diacritics: true,
        wrapper: true,
        data: {
            src: (function () {//Immediately call function
                return _cities_autocomplete_list;
            })(),
            filter: (list) => {
                list.sort(function (a, b) {
                    return !a.match.startsWith("<mark>");// Move to top 
                });
                return list;
            },
            keys: ["name"]
        },
        resultsList: {
            maxResults: 10,
            tabSelect: true,
        },
        resultItem: {
            highlight: true,
        },
        events: {
            input: {
                blur(event) {
                    if ($("#vacation_city").val() !== validDestination) {
                        $("#vacation_city").val((validDestination = ""));
                    }
                    destinationAutoComplete.close();
                },
                selection(event) {
                    var selection = event.detail.selection.value;
                    $("#vacation_city").val((validDestination = selection.name));

                    var poiOptRef = $("#infopoint_id").find('option[value="' + selection.id + '"]');
                    if (selection.id && poiOptRef.length) {
                        $("#suggest-poi").show().find("span").text($(poiOptRef).data("name"));
                        $("#infopoint_id").val(selection.id);
                    } else {
                        $("#suggest-poi").hide();
                    }
                },
            },
        }
    });
}

function sendForm() {
    var data = $(form).serialize();

    $(loader).show();
    formUtils.clearErrors();
    formUtils.hideMessage();

    $.post(thisTask("register"), data, (response) => {
        if (response.success) {
            $(location).attr('href', response.goTo);
        } else {

            if (response.hasOwnProperty('errorFields')) {
                formUtils.showErrors(response.errorFields);

                $(".step").each(function (index) {
                    if ($(this).find(".invalid-feedback.d-block").length) {
                        showTab(index);
                        return false;
                    }
                });
            }

            formUtils.showMessage(response.message);
            $(loader).hide();
        }
    }, 'json').done(function () {
    }).fail(function (xhr, status, error) {
        $(loader).hide();
    }).always(function () {
    });
}